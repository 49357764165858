// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blogpost-js": () => import("./../src/templates/blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-js" */),
  "component---src-templates-jobpost-js": () => import("./../src/templates/jobpost.js" /* webpackChunkName: "component---src-templates-jobpost-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-error-js": () => import("./../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-make-payment-js": () => import("./../src/pages/makePayment.js" /* webpackChunkName: "component---src-pages-make-payment-js" */),
  "component---src-pages-post-job-js": () => import("./../src/pages/postJob.js" /* webpackChunkName: "component---src-pages-post-job-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

